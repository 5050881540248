import React from 'react';
import ModalBase from './ModalBase';
import { ApprovalStatus } from '../types';
import { useAssetContext } from '../contexts/AssetContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark,
    faTriangleExclamation,
    faRobot,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { changeApprovalStatus } from '../utils/backendServices';

interface ApprovalStatusModalProps {
    isActive: boolean;
    onClose: () => void;
}

interface StatusConfig {
    icon: typeof faCheck;
    description: string;
    textColor: string;
}

// Default grey for inactive state
const defaultTextColor = '#595959';

const statusConfigs: Record<ApprovalStatus, StatusConfig> = {
    [ApprovalStatus.REJECTED]: {
        icon: faXmark,
        description: 'Asset has been rejected due to rule violations',
        textColor: '#D52214', // Base red text
    },
    [ApprovalStatus.REFERRED]: {
        icon: faTriangleExclamation,
        description: 'Requires additional review from moderator',
        textColor: '#E0B80E', // Base orange text
    },
    [ApprovalStatus.AI_APPROVED]: {
        icon: faRobot,
        description: 'Asset has been approved by the AI System',
        textColor: '#1976D2', // Base blue text
    },
    [ApprovalStatus.APPROVED]: {
        icon: faCheck,
        description: 'Asset has been approved for use',
        textColor: '#389E0D', // Base green text
    },
};

const hexToRGB = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
};

const deriveColors = (textColor: string) => {
    const { r, g, b } = hexToRGB(textColor);

    return {
        text: textColor,
        border: `rgba(${r}, ${g}, ${b}, 0.6)`,
        background: `rgba(${r}, ${g}, ${b}, 0.1)`,
    };
};

const getStyleObject = (
    status: ApprovalStatus,
    isCurrent: boolean,
    isHovered: boolean,
) => {
    const config = statusConfigs[status];
    const colors =
        isCurrent || isHovered
            ? deriveColors(config.textColor)
            : {
                  text: defaultTextColor,
                  border: 'rgba(89, 89, 89, 0.3)', // derived from defaultTextColor
                  background: 'transparent',
              };

    return {
        border: `1px solid ${colors.border}`,
        color: colors.text,
        backgroundColor: colors.background,
        opacity: isCurrent ? 0.85 : 1,
        transition: 'all 0.3s ease',
        cursor: isCurrent ? 'default' : 'pointer',
    };
};

const ApprovalStatusModal: React.FC<ApprovalStatusModalProps> = ({
    onClose,
    isActive,
}) => {
    const {
        state: {
            reviewResult: { approval_status, id },
        },
        handlers: { handleApprovalStatusChange },
    } = useAssetContext();

    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

    const handleChange = async (newStatus: ApprovalStatus) => {
        if (newStatus !== approval_status) {
            onClose();
            handleApprovalStatusChange(id, newStatus);
        }
    };

    const renderApprovalStatusButton = (
        approvalStatus: ApprovalStatus,
        isCurrent: boolean,
        index: number,
    ) => {
        const config = statusConfigs[approvalStatus];
        const isHovered = hoveredIndex === index;
        const colors =
            isCurrent || isHovered
                ? deriveColors(config.textColor)
                : {
                      text: defaultTextColor,
                      border: 'rgba(89, 89, 89, 0.3)',
                      background: 'transparent',
                  };

        return (
            <button
                className={`approval-status-button ${
                    isCurrent ? 'current' : ''
                }`}
                id={index.toString()}
                disabled={isCurrent}
                style={getStyleObject(approvalStatus, isCurrent, isHovered)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleChange(approvalStatus)}
            >
                <div className="approval-icon">
                    <FontAwesomeIcon
                        icon={config.icon}
                        style={{
                            color: colors.text,
                            transition: 'color 0.3s ease',
                        }}
                    />
                </div>
                <div className="text">
                    <p
                        className="label"
                        style={{
                            color: colors.text,
                            transition: 'color 0.3s ease',
                        }}
                    >
                        {approvalStatus}
                    </p>
                    <p
                        className="description"
                        style={{
                            color: colors.text,
                            transition: 'color 0.3s ease',
                        }}
                    >
                        {config.description}
                    </p>
                </div>
            </button>
        );
    };

    const allOptions = [
        ApprovalStatus.REJECTED,
        ApprovalStatus.REFERRED,
        ...(approval_status === ApprovalStatus.AI_APPROVED
            ? [ApprovalStatus.AI_APPROVED]
            : []),
        ApprovalStatus.APPROVED,
    ];

    return isActive ? (
        <ModalBase onClose={onClose}>
            <div className="showcase-modal-content">
                <div className="approval-status-modal">
                    {allOptions.map((option, i) =>
                        renderApprovalStatusButton(
                            option,
                            option === approval_status,
                            i,
                        ),
                    )}
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default ApprovalStatusModal;
