import { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // For URL params in React Router
import { useAuth, useOrganizationList } from '@clerk/clerk-react'; // Adjust according to your Clerk setup

export function syncActiveOrganizationFromURLToSession() {
    const { setActive, isLoaded } = useOrganizationList();

    const { orgId } = useAuth();

    const { orgId: urlOrgId } = useParams();

    useEffect(() => {
        if (!isLoaded) return;

        if (urlOrgId && urlOrgId !== orgId) {
            setActive({ organization: urlOrgId });
        }
    }, [orgId, isLoaded, setActive, urlOrgId]);

    return null;
}
