import { useOrganization } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';
import { Market } from '../types';

function useMarket(): Market | undefined {
    const [market, setMarket] = useState<Market>();
    const { organization } = useOrganization();

    useEffect(() => {
        if (organization) {
            const newMarket = organization.publicMetadata['market'] as Market;
            setMarket(newMarket);
        }
    }, [organization]);

    return market;
}

export default useMarket;
