import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';

type StatusIndicatorProps = {
    decision: 'Fail' | 'Pass' | 'Warning' | string;
    isBig: boolean;
    onEdit?: () => void;
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
    decision,
    isBig,
    onEdit,
}) => {
    let decisionClass = isBig ? 'status-indicator big ' : 'status-indicator ';
    let decisionText = '';
    if (decision === 'Rejected') {
        decisionClass += 'rejected';
        decisionText = 'Rejected';
    } else if (decision === 'AI Approved') {
        decisionClass += 'ai-approved';
        decisionText = 'AI Approved';
    } else if (decision === 'Approved') {
        decisionClass += 'approved';
        decisionText = 'Approved';
    } else if (decision === 'Referred') {
        decisionClass += 'referred';
        decisionText = 'Referred';
    }

    if (!decisionText) return null;

    return (
        <>
            {onEdit ? (
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Edit Status
                        </p>
                    }
                    placement="top"
                    arrow
                >
                    <button className={decisionClass} onClick={onEdit}>
                        <p>{decisionText}</p> <FontAwesomeIcon icon={faPen} />
                    </button>
                </Tooltip>
            ) : (
                <span className={decisionClass}>{decisionText}</span>
            )}
        </>
    );
};

export default StatusIndicator;
