import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronUp,
    faChevronDown,
    faCheck,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import './FilterButton.scss';
import { isMobile } from 'react-device-detect';
import { FilterOption, FilterType, AppliedFilter, FilterValue } from '../types';

interface FilterButtonProps {
    filterDisplayName: string;
    filterName: FilterType;
    isActive: boolean;
    onToggleActive: (filterName: FilterType | null) => void;
    appliedFilter: AppliedFilter | undefined;
    handleFilterApplication: (newFilter: AppliedFilter) => void;
    optionsWithCount: FilterOption[];
    inactivateFilter: (filterName: FilterType) => void;
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const FilterButton: FunctionComponent<FilterButtonProps> = ({
    filterDisplayName,
    filterName,
    isActive,
    onToggleActive,
    appliedFilter,
    handleFilterApplication,
    optionsWithCount,
    inactivateFilter,
}) => {
    const allFilterValues = optionsWithCount.map((o) => o.value);
    const initialCheckedFilterValues =
        appliedFilter?.selected_values ?? allFilterValues;

    const [checkedFilterValues, setCheckedFilterValues] = useState<
        FilterValue[]
    >(initialCheckedFilterValues);

    const arraysEqual = (
        filterValuesA: FilterValue[],
        filterValuesB: FilterValue[],
    ) => {
        return (
            filterValuesA.every((filterValueA) =>
                filterValuesB.some(
                    (filterValueB) => filterValueA.name === filterValueB.name,
                ),
            ) &&
            filterValuesB.every((filterValueB) =>
                filterValuesA.some(
                    (filterValueA) => filterValueB.name === filterValueA.name,
                ),
            )
        );
    };

    const resultCount: number = checkedFilterValues.reduce(
        (acc, checkedItem) =>
            acc +
            (optionsWithCount.find((o) => o.value.name == checkedItem.name)
                ?.count || 0),
        0,
    );

    const handleButtonClick = () => {
        onToggleActive(filterName);
        setCheckedFilterValues(initialCheckedFilterValues);
    };

    const handleApplyButtonClick = () => {
        if (arraysEqual(allFilterValues, checkedFilterValues)) {
            inactivateFilter(filterName);
        } else {
            handleFilterApplication({
                type: filterName,
                selected_values: checkedFilterValues,
            });
        }

        onToggleActive(filterName);
    };

    const handleCheckboxChange = (
        filterValue: FilterValue,
        isChecked: boolean,
    ) => {
        if (isChecked) {
            setCheckedFilterValues((prev) => [...prev, filterValue]);
        } else {
            setCheckedFilterValues((prev) =>
                prev.filter((p) => p.name !== filterValue.name),
            );
        }
    };

    const handleSelectAll = () => {
        setCheckedFilterValues(optionsWithCount.map((o) => o.value));
    };
    const handleUnselectAll = () => {
        setCheckedFilterValues([]);
    };

    const handleIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!appliedFilter) {
            onToggleActive(filterName);
        } else {
            onToggleActive(null);
            setCheckedFilterValues(allFilterValues);
            inactivateFilter(filterName);
        }
    };
    return (
        <div className={'filter-button-container'}>
            <div
                className={`filter-button ${
                    appliedFilter || isActive ? 'applied' : ''
                }`}
                onClick={handleButtonClick}
            >
                <div className="filter-title">{filterDisplayName}</div>
                <FontAwesomeIcon
                    icon={
                        appliedFilter
                            ? faXmark
                            : isActive
                              ? faChevronDown
                              : faChevronUp
                    }
                    onClick={handleIconClick}
                />
            </div>
            {isActive && Object.keys(optionsWithCount).length > 0 && (
                <div className={`filter-dropdown ${isMobile ? 'mobile' : ''}`}>
                    <div className="select-unselect-container">
                        <div
                            className="select-button"
                            onClick={handleSelectAll}
                        >
                            Select all
                        </div>
                        <div className="dot-seperator">•</div>
                        <div
                            className="select-button"
                            onClick={handleUnselectAll}
                        >
                            Unselect all
                        </div>
                        <div
                            className="escape-button"
                            onClick={handleButtonClick}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>

                    {optionsWithCount.map((o, index) => (
                        <label className="checkbox-label" key={index}>
                            <HiddenCheckbox
                                checked={
                                    !!checkedFilterValues.find(
                                        (f) => f.name == o.value.name,
                                    )
                                }
                                onChange={(e) =>
                                    handleCheckboxChange(
                                        o.value,
                                        e.target.checked,
                                    )
                                }
                            />
                            <div
                                className={`checkbox ${
                                    checkedFilterValues.find(
                                        (f) => f.name == o.value.name,
                                    )
                                        ? 'checked'
                                        : ''
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="fa-check"
                                />
                            </div>
                            <div className="checkbox-name">
                                {o.value.display_name}
                            </div>
                            <div className="checkbox-count">{o.count}</div>
                        </label>
                    ))}
                    {!arraysEqual(
                        initialCheckedFilterValues,
                        checkedFilterValues,
                    ) && checkedFilterValues.length > 0 ? (
                        <div
                            className="apply-button active"
                            onClick={handleApplyButtonClick}
                        >
                            Show {resultCount} assets
                        </div>
                    ) : (
                        <div className="apply-button inactive">
                            Show {resultCount} assets
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FilterButton;
