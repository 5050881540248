export const clerkVariables = {
    // colorPrimary: '#5944fd',
    fontSize: '1rem',
};

export const clerkSignInUpElements = {
    logoBox: {
        height: '30px',
    },
    card: {
        padding: '40px',
    },
    cardBox: {
        width: '380px',
    },
};
