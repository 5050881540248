import { useEffect } from 'react';
import {
    fetchUploadStatus,
    handleLibraryRequest,
} from '../utils/backendServices';
import { UploadProgress } from '../contexts/UploadProgressContext';
import { useInterval } from './useInterval';
import { useLibraryContext } from '../contexts/LibraryContext';

const calculateProgress = (timeStamp: Date): number => {
    const threshold = 90; // The maximum progress value
    const maxTime = 40; // Maximum time in seconds

    // Calculate the current time in seconds
    const currentTime = (new Date().getTime() - timeStamp.getTime()) / 1000;

    // Ensure that the progress does not exceed the threshold
    const progress = Math.min((currentTime / maxTime) * threshold, threshold);

    return progress;
};

export function useUploadProgress(
    uploadProgressArrayRef: React.MutableRefObject<UploadProgress[]>,
    setUploadProgressArray: React.Dispatch<
        React.SetStateAction<UploadProgress[]>
    >,
): void {
    const libraryContext = useLibraryContext();
    const fetchAndSetUploadProgress = async () => {
        const uploadStatus = await fetchUploadStatus();
        const oldUploadProgressArray = uploadProgressArrayRef.current;
        uploadStatus.forEach((newItem) => {
            if (
                uploadProgressArrayRef.current.some(
                    (item) => item.imageId === newItem.image_id,
                )
            ) {
                uploadProgressArrayRef.current =
                    uploadProgressArrayRef.current.map((item) =>
                        item.imageId === newItem.image_id
                            ? {
                                  ...item,
                                  progress: calculateProgress(
                                      new Date(newItem.time_stamp),
                                  ),
                                  imageId: newItem.image_id,
                                  imageUrl: newItem.image_url,
                                  title: newItem.title,
                                  timeStamp: new Date(newItem.time_stamp),
                                  status: newItem.status,
                                  fetchedResult: newItem.result,
                              }
                            : item,
                    );
            } else {
                uploadProgressArrayRef.current = [
                    ...uploadProgressArrayRef.current,
                    {
                        active: true,
                        progress: 0,
                        imageId: newItem.image_id,
                        imageUrl: newItem.image_url,
                        title: newItem.title,
                        timeStamp: new Date(newItem.time_stamp),
                        status: newItem.status,
                        fetchedResult: newItem.result,
                    },
                ];
            }
        });
        const newItemFinished = uploadProgressArrayRef.current.some(
            (newItem) =>
                newItem.status == 'successful' &&
                oldUploadProgressArray.every((oldItem) => {
                    if (oldItem.imageId === newItem.imageId) {
                        return oldItem.status !== 'successful';
                    } else {
                        return true;
                    }
                }),
        );
        setUploadProgressArray(uploadProgressArrayRef.current);
        if (newItemFinished) {
            handleLibraryRequest(
                libraryContext,
                libraryContext.state.currentPage,
                false,
            ).catch((error) => {
                console.log(error);
            });
        }
    };

    const updateProgress = async () => {
        setUploadProgressArray(
            uploadProgressArrayRef.current.map((item) =>
                item.status === 'checking'
                    ? {
                          ...item,
                          progress: calculateProgress(item.timeStamp),
                      }
                    : item,
            ),
        );
    };

    useEffect(() => {
        fetchAndSetUploadProgress();
    }, []);

    useInterval(
        () => {
            fetchAndSetUploadProgress();
        },
        uploadProgressArrayRef.current.some(
            (item) => item.status === 'checking',
        )
            ? 3000
            : 60000,
    );

    useInterval(
        () => {
            updateProgress();
        },
        uploadProgressArrayRef.current.some(
            (item) => item.status === 'checking',
        )
            ? 100
            : null,
    );
}
