import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsis,
    faEllipsisVertical,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import StatusIndicator from './StatusIndicator';
import AssetDetailsBackButton from './AssetDetailsBackButton';
import { useAssetContext } from '../contexts/AssetContext';
import { useModalContext } from '../contexts/ModalContext';
import ApprovalStatusModal from './ApprovalStatusModal';

const AssetDetailsTitleBar: React.FC = () => {
    const {
        state: {
            reviewResult: {
                properties: { asset_name },
                approval_status,
            },
        },
    } = useAssetContext();
    const { setModalText } = useModalContext();
    const [showApprovalStatus, setShowApprovalStatus] = useState(false);
    return (
        <div className="approval-container">
            <ApprovalStatusModal
                isActive={showApprovalStatus}
                onClose={() => setShowApprovalStatus(false)}
            />
            <h2>{asset_name}</h2>
            <div className="approval-actions">
                <StatusIndicator
                    decision={approval_status}
                    isBig={true}
                    onEdit={() => setShowApprovalStatus(true)}
                />
                <button
                    className="approval-delete"
                    onClick={() =>
                        setModalText({
                            title: 'Coming Soon',
                            text: 'This will launch soon, allowing you to delete a visual.',
                        })
                    }
                >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </button>
                {/* <AssetDetailsBackButton /> */}
            </div>
        </div>
    );
};

export default AssetDetailsTitleBar;
