import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '@clerk/clerk-react';

// Hook implementation
export const useNavigateWithinOrg = () => {
    const navigate = useNavigate();
    const { organization } = useOrganization();

    return useCallback(
        (path: string) => {
            if (!organization?.slug) {
                console.warn('No organization found.');
                navigate('/orgs');
                return;
            }

            const normalizedPath = path.startsWith('/') ? path : `/${path}`;
            navigate(`/orgs/${organization.slug}${normalizedPath}`);
        },
        [navigate, organization?.slug],
    );
};

interface NavigateWithinOrgProps {
    to: string;
}

export const NavigateWithinOrg: React.FC<NavigateWithinOrgProps> = ({ to }) => {
    const navigateWithinOrg = useNavigateWithinOrg();

    useEffect(() => {
        navigateWithinOrg(to);
    }, [navigateWithinOrg, to]);

    return null;
};

export default NavigateWithinOrg;
