import React, { useState } from 'react';
import { BaseAssetReviewResult, LabelCheckResult } from '../types';
import AssetCard from './AssetCard';
import { useNavigateWithinOrg } from '../utils/navigation';

interface ResultsTableProps {
    libraryAssets: BaseAssetReviewResult[];
    imageUrls: { [id: string]: string };
}

const AssetGallery: React.FC<ResultsTableProps> = ({
    libraryAssets,
    imageUrls,
}) => {
    const navigate = useNavigateWithinOrg();
    // const [imageUrls, setImageUrls] = useState<{ [id: string]: string }>({});
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    const handleSelect = (asset: BaseAssetReviewResult) => {
        setSelectedItemId(asset.id);
        navigate(`/library/${asset.id}`);
    };

    return (
        <div className="results-table-grid">
            {libraryAssets.length > 0 ? (
                libraryAssets.map((asset) => (
                    <AssetCard
                        key={asset.id}
                        asset={asset}
                        imageUrl={imageUrls[asset.id]}
                        onSelect={handleSelect}
                        isSelected={selectedItemId === asset.id}
                    />
                ))
            ) : (
                <div className="no-results-message">
                    No results for that filter
                </div>
            )}
        </div>
    );
};

export default AssetGallery;
