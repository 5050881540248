import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';

export default function Index() {
    const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

    if (!PUBLISHABLE_KEY) {
        throw new Error(
            'Clerk publishable key is undefined. Please set it in the environment variables.',
        );
    }

    return (
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ClerkProvider>
    );
}

const root = document.getElementById('root');
if (root) {
    ReactDOM.createRoot(root).render(<Index />);
} else {
    console.error('Failed to find root element');
}

reportWebVitals();
