import { useState, useEffect } from 'react';

/**
 * A custom hook for managing the browser's URL hash.
 */
function useHash(): { hash: string; setHashValue: (newHash: string) => void } {
    const [hash, setHash] = useState<string>(window.location.hash);

    useEffect(() => {
        const updateHash = () => setHash(window.location.hash);

        // Listen for hash changes
        window.addEventListener('hashchange', updateHash);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('hashchange', updateHash);
        };
    }, []);

    const setHashValue = (newHash: string) => {
        if (newHash !== hash) {
            window.location.hash = newHash;
        }
    };

    return { hash, setHashValue };
}

export default useHash;
