import React, { useEffect, useState } from 'react';
import { BaseAssetReviewResult, LabelCheckResult } from '../types';
import StatusIndicator from './StatusIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faUser, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { formatDate } from '../utils/timeUtils';
import { fetchUserDetails } from '../utils/backendServices';
// import useNumberItems from '../hooks/useNumberItems';

interface AssetCardProps {
    asset: BaseAssetReviewResult;
    imageUrl: string;
    onSelect: (asset: BaseAssetReviewResult) => void;
    isSelected: boolean;
}

const AssetCard: React.FC<AssetCardProps> = ({
    asset,
    imageUrl,
    onSelect,
    isSelected,
}) => {
    const [userName, setUserName] = useState<string | null>(null);
    useEffect(() => {
        fetchUserDetails(asset.user_id)
            .then((userDetails) => {
                setUserName(
                    `${userDetails.first_name} ${userDetails.last_name}`,
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    return (
        <div
            className={`rule-result-card ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(asset)}
        >
            <div className="main-image-container">
                <img
                    className="main-image"
                    src={imageUrl}
                    alt={`Thumbnail for ${asset.properties.asset_name}`}
                />
            </div>
            <div className="info-section">
                <div className="upper-container">
                    <p className="project-name">
                        {asset.properties.asset_name}
                    </p>
                    <StatusIndicator
                        decision={asset.approval_status}
                        isBig={false}
                    />
                </div>

                <div className="lower-container">
                    <div className="icon-value-container">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>
                            {formatDate(asset.run_info.timestamp, false, true)}
                        </p>
                    </div>
                    {userName && (
                        <div className="icon-value-container">
                            <FontAwesomeIcon icon={faUser} />
                            <p>{userName}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssetCard;
