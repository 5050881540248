import React, {
    createContext,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useContext,
    useRef,
    useMemo,
} from 'react';
import { UploadState } from '../types';
import {
    fetchUploadState,
    postHideUploadState,
} from '../utils/backendServices';
import { useAuth, useOrganization } from '@clerk/clerk-react';

interface UploadContextType {
    uploadStateArray: UploadState[];
    triggerFetch: () => void;
    hideUploadState: (assetId: string) => Promise<void>;
}

export const UploadContext = createContext<UploadContextType | undefined>(
    undefined,
);

interface UploadStateProviderProps {
    children: ReactNode;
}

export const UploadStateProvider: React.FC<UploadStateProviderProps> = ({
    children,
}) => {
    const [uploadStateArray, setUploadStateArray] = useState<UploadState[]>([]);

    const { organization, isLoaded } = useOrganization();
    const organizationRef = useRef(organization); // Ref to hold the latest value of isSignedIn

    // Update the ref with the latest isSignedIn value when it changes
    useEffect(() => {
        organizationRef.current = organization;
    }, [organization]);

    const updateUploadState = useCallback(async () => {
        if (organizationRef.current) {
            const newUploadStateArray = await fetchUploadState();
            setUploadStateArray(newUploadStateArray);
        }
    }, []);

    useEffect(() => {
        const intervalTime = uploadStateArray.length === 0 ? 30000 : 3000;
        const intervalId = setInterval(() => {
            updateUploadState();
        }, intervalTime);

        return () => clearInterval(intervalId);
    }, [uploadStateArray, updateUploadState]);

    const triggerFetch = useCallback(() => {
        updateUploadState();
    }, [updateUploadState]);

    const hideUploadState = useCallback(
        async (assetId: string) => {
            const updatedStateArray = uploadStateArray.map((item) =>
                item.id === assetId
                    ? {
                          ...item,
                          display: false,
                      }
                    : item,
            );
            setUploadStateArray(updatedStateArray);
            await postHideUploadState(assetId);
            triggerFetch();
        },
        [uploadStateArray],
    );

    useEffect(() => {
        if (isLoaded) {
            updateUploadState();
        }
    }, [isLoaded]);

    return (
        <UploadContext.Provider
            value={{ uploadStateArray, triggerFetch, hideUploadState }}
        >
            {children}
        </UploadContext.Provider>
    );
};

export function useUploadContext() {
    const uploadContext = useContext(UploadContext);

    if (uploadContext === undefined) {
        throw new Error('useUploadContext must be used with an UploadContext');
    }

    return uploadContext;
}

export function useLatestSuccessfulUpload(): UploadState | null {
    const { uploadStateArray } = useUploadContext();
    const latestUploadRef = useRef<UploadState | null>(null);

    return useMemo(() => {
        // Find the latest successful upload without creating intermediate arrays
        let latestTime = latestUploadRef.current?.upload_time ?? -1;
        let newLatestUpload: UploadState | null = latestUploadRef.current;

        for (const upload of uploadStateArray) {
            if (
                upload.state === 'successful' &&
                upload.upload_time > latestTime
            ) {
                newLatestUpload = upload;
                latestTime = upload.upload_time;
            }
        }

        // Only update the ref if we found a different latest upload
        if (
            newLatestUpload?.id !== latestUploadRef.current?.id ||
            newLatestUpload?.upload_time !==
                latestUploadRef.current?.upload_time
        ) {
            latestUploadRef.current = newLatestUpload;
        }

        return latestUploadRef.current;
    }, [uploadStateArray]);
}
