import axios from 'axios';

let baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
if (baseURL !== 'http://localhost:8000') {
    baseURL += '/api';
}

// export const getInstance = (timeout?: number) => {
export const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'X-API-Key': process.env.REACT_APP_API_KEY || 'localhost',
    },
});
export default instance;
