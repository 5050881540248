import { Any } from 'react-spring';
import { FetchedResult } from './contexts/UploadProgressContext';

export interface UploadResult {
    success: boolean;
    outcome?: 'Accept' | 'Reject' | 'Warning';
    id?: string;
}

export interface User {
    id: string;
    email: string;
    name: string;
}
export interface UserDetails {
    id: string;
    first_name: string;
    last_name: string;
    email_address: string;
    avatar_url: string;
}
export enum Category {
    FOOD = 'Food',
    WINE = 'Wine',
    BEVERAGE = 'Beverage',
}

export interface SpecItem {
    id: string;
    user_id: string;
    org_id: string;
    category: Category;
    spec_name: string;
    spec_notes: string | null;
    timestamp: number;
    file_format: FileFormat | null;
}

export interface LabelStatus {
    is_active: boolean;
}

export enum ApprovalStatus {
    AI_APPROVED = 'AI Approved',
    APPROVED = 'Approved',
    REFERRED = 'Referred',
    REJECTED = 'Rejected',
}

export interface LabelCheckResult {
    id: string;
    user_id: string;
    org_id: string;
    spec_id: string;
    status: LabelStatus;
    user_input: UserInput;
    run_info: RunInfo;
    approval_status: ApprovalStatus;
}

export interface SpecToken {
    text: string;
}

export interface BoundingRect {
    x_min: number;
    x_max: number;
    y_min: number;
    y_max: number;
}

export interface LabelToken {
    text: string;
    bounding_rect: BoundingRect;
}

export enum CheckType {
    SPEC_STRING = 'SPEC_STRING',
    SPEC_STRING_LIST = 'SPEC_STRING_LIST',
    SPEC_TABLE = 'SPEC_TABLE',
    SPEC_SYMBOL = 'SPEC_SYMBOL',
}

export enum SpecCheckOutcome {
    PASS = 'PASS',
    WARNING = 'WARNING',
    FAIL = 'FAIL',
}

export enum TokenMatchType {
    FULL_MATCH = 'FULL_MATCH',
    NORMALISED_MATCH = 'NORMALISED_MATCH',
    PARTIAL_MATCH = 'PARTIAL_MATCH',
    NO_MATCH = 'NO_MATCH',
    IRRELEVANT_TOKEN = 'IRRELEVANT_TOKEN',
}

export enum SequenceMatchType {
    FULL_MATCH = 'FULL_MATCH',
    PARTIAL_MATCH = 'PARTIAL_MATCH',
    NO_MATCH = 'NO_MATCH',
}

export interface CheckedToken {
    spec_token: SpecToken | null;
    label_token: LabelToken | null;
    token_match_type: TokenMatchType;
    match_ratio: number;
}

export interface MatchSequence {
    checked_tokens: CheckedToken[];
    sequence_match_ratio: number;
    sequence_match_type: SequenceMatchType;
}

export interface SpecTableRowChecked {
    header: boolean;
    name: string;
    values: (MatchSequence | null)[];
}

export interface SpecTableChecked {
    col_names: string[] | null;
    rows: SpecTableRowChecked[];
}

export interface SpecTableRow {
    header: boolean;
    name: string;
    values: (string | null)[];
}

export interface SpecTableContent {
    col_names: string[] | null;
    rows: SpecTableRow[];
}

export interface Summary {
    text: string | string[];
    bullets: string[] | null;
}

export interface Dimensions {
    height: number;
    width: number;
}

export interface DetectedSymbol {
    value: number;
    bounding_rect: BoundingRect;
    rotation: 0 | 90 | 180 | 270;
    dimensions: Dimensions;
}

export interface SpecSymbolResults {
    detected_symbols: DetectedSymbol[];
}

export interface SpecStringResults {
    match_sequence: MatchSequence;
    text_size: number | null;
}

export interface SpecStringListResults {
    match_sequences: MatchSequence[];
    text_size: number | null;
}

export interface FetchComplianceDataParams {
    pageNumber: number;
    numItems: string;
    filters: AppliedFilter[];
    // countries: string[];
    // industries: string[];
    search?: string;
    sortField?: string;
    sortOrder?: string;
}

export interface NutritionFopInfo {
    label: string;
    amount: string;
    statement: 'High' | 'Medium' | 'Low' | null;
    ri_percent: string;
}

export interface NutritionsFop {
    energy: NutritionFopInfo;
    fat: NutritionFopInfo;
    saturates: NutritionFopInfo;
    sugars: NutritionFopInfo;
    salt: NutritionFopInfo;
}

export interface NutritionBopRow {
    label: string;
    amount_per_100g: string;
    amount_per_portion: string;
    ri_percent: string;
}

export interface NutritionsBop {
    headers: NutritionBopRow;
    energy: NutritionBopRow;
    fat: NutritionBopRow;
    saturates: NutritionBopRow;
    carbohydrates: NutritionBopRow;
    sugars: NutritionBopRow;
    fibre: NutritionBopRow;
    protein: NutritionBopRow;
    salt: NutritionBopRow;
}

export interface FoodSpecs {
    fop_nutritions: NutritionsFop;
    bop_nutritions: NutritionsBop;
    legal_name: string;
    cooking_instructions: string[];
    serving_number: string | null;
    reference_intake_energy: string | null;
    declared_quantity: string | null;
    ingredients: string | null;
}

export interface WineSpecs {
    legal_name: string; // The legal name and description of the wine.
    storage_instructions: string; // Instructions on how to store the wine.
    energy_information: string[]; // Caloric and energy content information per servings.
    serving_number: string; // Information on how many servings are in the bottle.
    declared_quantity: string; // The total volume of the wine bottle.
    abv: string; // Alcohol by volume expressed as a percentage.
    origin_statement: string; // The statement of origin for the wine.
}

export interface SizeReq {
    min: number | null;
    max: number | null;
}

export interface SpecStringContent {
    text: string;
    text_size: SizeReq | null;
}

export interface SpecStringListContent {
    text: string[];
    text_size: SizeReq | null;
}

export interface SpecSymbolContent {
    symbol_id: string;
    symbol_height: SizeReq | null;
}

export interface SpecDetail {
    name: string;
    display_name: string;
    description: string | null;
    check_type: CheckType;
    content:
        | SpecStringContent
        | SpecStringListContent
        | SpecTableContent
        | SpecSymbolContent;
}
export interface SpecDetailStringList extends SpecDetail {
    content: SpecStringListContent;
    check_type: CheckType.SPEC_STRING_LIST;
}
export interface SpecDetailTable extends SpecDetail {
    content: SpecTableContent;
    check_type: CheckType.SPEC_STRING_LIST;
}

export interface SpecDetailSymbol extends SpecDetail {
    content: SpecSymbolContent;
    check_type: CheckType.SPEC_SYMBOL;
}
export interface SpecDetailString extends SpecDetail {
    check_type: CheckType.SPEC_STRING;
    content: SpecStringContent;
}

export interface SpecItemDetailed extends SpecItem {
    specs: SpecDetail[];
}

export interface SpecificationDataResponse {
    spec_data: SpecItemDetailed[];
    total_pages: number;
    prev_page?: number;
    next_page?: number;
}

export interface AnalyticsDataResponse {
    filter_count_dict: Record<string, Record<string, number>>;
    user_outcome_dict: {
        user: Record<string, number>;
        outcome: Record<string, number>;
    };
    rule_failure_dict: Record<
        string,
        {
            count: number;
            rule_description: string;
            short_rule_description: string;
            rule_type: 'Fail' | 'Warning';
        }
    >;
}
export interface FileUploadItem {
    user: string;
    image_id: string;
    user_input: any;
    image_url: string;
}

export interface UserInput {
    market: string;
    asset_name: string;
}

export interface RunInfo {
    timestamp: number;
    run_duration: number;
    run_type: 'live' | 'local';
}

export interface Outcome {
    rules_passed: number;
    rules_flagged: number;
    decision: string;
}

export interface UserInfo {
    username: string;
    email: string;
}

export interface ImageData {
    text: ExtractedText;
}

export interface ExtractedText {
    native_text: string;
    en_text: string;
    language_code: string;
}

export interface SortOption {
    label: string;
    variable: 'id' | 'industry' | 'user' | 'country' | 'date';
    sortOrder: 'ascending' | 'descending';
}

export interface DeleteData {
    item_id: string;
    user: string;
}

export interface UploadStatusResponse {
    status: 'checking' | 'successful' | 'error';
    image_url: string;
    image_id: string;
    title: string;
    time_stamp: string;
    result: null | FetchedResult;
}

export type State =
    | 'pending'
    | 'uploading'
    | 'processing'
    | 'successful'
    | 'failed';
export type UploadType = 'spec' | 'label';

export interface UploadState {
    id: string;
    state: State;
    upload_type: UploadType;
    file_format: FileFormat;
    user_id: string;
    org_id: string;
    asset_name: string;
    upload_time: number;
    display: boolean;
}

export interface OngoingUploadState extends UploadState {
    state: 'pending' | 'uploading' | 'processing';
}

export interface FailedUploadState extends UploadState {
    state: 'failed';
    error_message: string;
}

export interface SuccessfulUploadState extends UploadState {
    state: 'successful';
    outcome: string;
}

export enum FileFormat {
    PDF = 'pdf',
    JPEG = 'jpeg',
    JPG = 'jpg',
    PNG = 'png',
}

export interface FileConfig {
    file_format: FileFormat;
}

export interface ImageFileConfig extends FileConfig {
    file_format: FileFormat.JPEG | FileFormat.JPG | FileFormat.PNG;
}

export interface PDFFileConfig extends FileConfig {
    file_format: FileFormat.PDF;
    page: number;
}

export interface ReviewData {
    file_config: ImageFileConfig | PDFFileConfig;
    properties:
        | DutchAssetProperties
        | BelgiumAssetProperties
        | FrenchAssetProperties;
}

export interface FilterConfig {
    name: FilterType;
    display_name: string;
}

export interface BoundingRect {
    x_min: number;
    x_max: number;
    y_min: number;
    y_max: number;
}

export interface AssetStatus {
    is_active: boolean;
}

export interface RunInfo {
    timestamp: number;
    run_duration: number;
    run_type: 'live' | 'local';
}

export enum Market {
    BELUX = 'BeLux',
    FRANCE = 'France',
    NETHERLANDS = 'Netherlands',
}

export enum AlcoholStrength {
    ABV_0_0 = '0.0% ABV',
    ABV_OVER_0_5 = '>0.5% ABV',
}

export enum YesNo {
    YES = 'Yes',
    NO = 'No',
}

export enum Brand {
    JUPILER = 'Jupiler',
    VICTORIA = 'Victoria',
    TRIPEL_KARMELIET = 'Tripel Karmeliet',
    STELLA_ARTOIS = 'Stella Artois',
    LEFFE = 'Leffe',
    BUD = 'Bud',
    HERTOG_JAN = 'Hertog Jan',
    CORONA = 'Corona',
    OTHER = 'Other',
}

export interface BaseAssetProperties {
    asset_name: string;
    market: Market;
    brand: Brand;
}

export interface DutchAssetProperties extends BaseAssetProperties {
    market: Market.NETHERLANDS;
    alcohol_strength: AlcoholStrength;
}

export interface BelgiumAssetProperties extends BaseAssetProperties {
    market: Market.BELUX;
    on_public_roads: YesNo;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FrenchAssetProperties extends BaseAssetProperties {
    market: Market.FRANCE;
}

export interface BaseAssetReviewResult {
    id: string;
    user_id: string;
    org_id: string;
    status: AssetStatus;
    properties:
        | DutchAssetProperties
        | BelgiumAssetProperties
        | FrenchAssetProperties;
    run_info: RunInfo;
    approval_status: ApprovalStatus;
}

export interface Rule {
    name: string;
    display_name: string;
    description: string;
}

export enum RuleOutcome {
    PASS = 'Pass',
    WARNING = 'Warning',
    FAIL = 'Fail',
}

export interface VisalEvidence {
    note: string | null;
    bounding_rect: BoundingRect;
}

export interface RuleResult extends Rule {
    outcome: RuleOutcome;
    summary: Summary;
    copy_summary: string | null;
    evidence_items: VisalEvidence[];
}
export enum ApprovalStatusChangeType {
    INITIAL = 'initial',
    USER_CHANGE = 'user change',
}

export enum ActivityType {
    UPLOAD = 'upload',
    COMMENT = 'comment',
    STATUS_CHANGE = 'status change',
}

export interface ApprovalStatusChange {
    new_status: ApprovalStatus;
    change_type: ApprovalStatusChangeType;
    reason?: string;
    user_id: string;
    timestamp: number;
}

export interface Comment {
    id: string;
    content: string;
    user_id: string;
    timestamp: number;
}

export interface UploadActivityContent {
    approval_status: ApprovalStatus;
    asset_name: string;
}

export interface CommentActivityContent {
    content: string;
}

export interface StatusChangeActivityContent {
    old_status: ApprovalStatus;
    new_status: ApprovalStatus;
    reason: string | null;
}

export interface Activity {
    activity_type: ActivityType;
    user_details: UserDetails;
    timestamp: number;
    content:
        | UploadActivityContent
        | CommentActivityContent
        | StatusChangeActivityContent;
}

export interface UploadActivity extends Activity {
    activity_type: ActivityType.UPLOAD;
    content: UploadActivityContent;
}

export interface CommentActivity extends Activity {
    activity_type: ActivityType.COMMENT;
    content: CommentActivityContent;
}

export interface StatusChangeActivity extends Activity {
    activity_type: ActivityType.STATUS_CHANGE;
    content: StatusChangeActivityContent;
}

export interface AssetReviewResult extends BaseAssetReviewResult {
    rule_results: RuleResult[];
}

export interface LibraryResponse {
    assets: BaseAssetReviewResult[];
    total_pages: number;
    prev_page: number;
    next_page?: number;
    outgoing_filters: OutgoingFilter[];
    image_urls: { [id: string]: string };
}

export interface AssetDetailResponse {
    review_result: AssetReviewResult;
    image_url: string;
    activities: (StatusChangeActivity | CommentActivity | UploadActivity)[];
}

export enum FilterType {
    USER = 'user',
    DATE = 'date',
    APPROVAL = 'approval',
    BRAND = 'brand',
}

export interface FilterValue {
    name: string;
    display_name: string;
}

export interface FilterOption {
    value: FilterValue;
    count: number;
}

export interface Filter {
    type: FilterType;
}

export interface OutgoingFilter extends Filter {
    options: FilterOption[];
}

export interface AppliedFilter extends Filter {
    selected_values: FilterValue[];
}
