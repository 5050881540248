import React from 'react';
import ModalBase from './ModalBase';
import { ModalText } from '../contexts/ModalContext';

interface FeatureNotAvailableModalProps {
    modalText: ModalText | null;
    onClose: () => void;
}

const FeatureNotAvailableModal: React.FC<FeatureNotAvailableModalProps> = ({
    modalText,
    onClose,
}) => {
    return modalText ? (
        <ModalBase onClose={onClose}>
            <div className="showcase-modal-content">
                <h2>{modalText.title}</h2>
                <p className="delete-text">{modalText.text}</p>
                <div className="button-row">
                    <div
                        className="email-button"
                        onClick={(e) => {
                            window.location.href =
                                'mailto:info@algospark.com?subject=Interest%20in%20Label%20Checker';
                            e.preventDefault();
                        }}
                    >
                        Reach Out
                    </div>
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default FeatureNotAvailableModal;
