import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock,
    faEarthEurope,
    faIndustry,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import './FilterButton.scss';
import sort_icon from './../sort.svg';
import { FilterType, SortOption } from '../types';
import { isMobile } from 'react-device-detect';

export interface SortButtonProps {
    appliedSortOption: SortOption;
    setAppliedSortOption: (value: React.SetStateAction<SortOption>) => void;
    sortOptions: SortOption[];
    onToggleActive: (activeToggle: FilterType | 'sort') => void;
    showDropdown: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepEqual(x: any, y: any): boolean {
    const tx = typeof x;
    const ty = typeof y;

    if (x && y && typeof x === 'object' && tx === ty) {
        const keysX = Object.keys(x);
        const keysY = Object.keys(y);

        if (keysX.length !== keysY.length) {
            return false;
        }

        return keysX.every((key) => deepEqual(x[key], y[key]));
    }

    return x === y;
}

const SortButton: FunctionComponent<SortButtonProps> = ({
    sortOptions,
    appliedSortOption,
    setAppliedSortOption,
    onToggleActive,
    showDropdown,
}) => {
    const iconMapping = {
        id: <FontAwesomeIcon icon={faClock} className="fa-clock" />,
        date: <FontAwesomeIcon icon={faClock} className="fa-clock" />,
        industry: <FontAwesomeIcon icon={faIndustry} className="fa-industry" />,
        country: (
            <FontAwesomeIcon icon={faEarthEurope} className="fa-earth-europe" />
        ),
        user: <FontAwesomeIcon icon={faUser} className="fa-user" />,
    };

    const handleButtonClick = () => {
        onToggleActive('sort');
    };

    const handleOptionChange = (newSortOption: SortOption) => {
        if (!deepEqual(appliedSortOption, newSortOption)) {
            setAppliedSortOption(newSortOption);
        }
        onToggleActive('sort');
    };

    return (
        <div className={'filter-button-container'}>
            <div
                className={`filter-button ${showDropdown ? 'applied' : ''}`}
                onClick={handleButtonClick}
            >
                <div className="filter-title">{appliedSortOption.label}</div>

                <img src={sort_icon} className="sort-logo" />
            </div>
            {showDropdown && (
                <div
                    className={`filter-dropdown sort ${
                        isMobile ? 'mobile' : ''
                    }`}
                >
                    {sortOptions.map((option, index) => (
                        <label
                            className={`sort-option ${
                                deepEqual(appliedSortOption, option)
                                    ? 'applied'
                                    : ''
                            }`}
                            key={index}
                            onClick={() => handleOptionChange(option)}
                        >
                            <div className="sort-icon">
                                {iconMapping[option.variable]}
                            </div>
                            <div className="sort-label">{option.label}</div>
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SortButton;
