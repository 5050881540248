import React, { useState } from 'react';
import { RuleResult, RuleOutcome } from '../types';
import {
    faExclamation,
    faCopy as faCopySolid,
    faEye as faEyeSolid,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import {
    faCopy,
    faEye,
    faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModalContext } from '../contexts/ModalContext';

interface RuleCardProps {
    ruleResult: RuleResult;
    handleFeedbackClick: () => void;
    focussed: boolean;
    handleFocus: () => void;
}

const colourMap = {
    [RuleOutcome.FAIL]: 'red',
    [RuleOutcome.WARNING]: '#E0B80E',
    [RuleOutcome.PASS]: '#389E0D',
};

const RuleCard: React.FC<RuleCardProps> = ({
    ruleResult,
    handleFeedbackClick,
    focussed,
    handleFocus,
}) => {
    const [active, setActive] = useState(false);
    const [copied, setCopied] = useState(false);

    const { setModalText } = useModalContext();

    const handleCopyClick = (copyText: string) => {
        navigator.clipboard
            .writeText(copyText)
            .then(() => {
                setCopied(true); // Change the icon to a checkmark
                setTimeout(() => setCopied(false), 5000); // Change the icon back to the copy icon after 10 seconds
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };
    const summaryArray = () => {
        if (Array.isArray(ruleResult.summary.text)) {
            return ruleResult.summary.text;
        } else {
            return [ruleResult.summary.text];
        }
    };
    return (
        <div
            className="rules-check-card"
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <div
                className="decision-colour-box"
                style={{
                    backgroundColor: colourMap[ruleResult.outcome],
                }}
            />
            <div className="content-container">
                <div className="upper-container">
                    <div className="text-container">
                        <p
                            className="check-name"
                            style={{ marginRight: '5px' }}
                        >
                            {ruleResult.display_name}
                            {ruleResult.description && (
                                <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            {ruleResult.description}
                                        </p>
                                    }
                                    placement="top"
                                    arrow
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </Tooltip>
                            )}
                        </p>
                        {summaryArray().map((text, index) => (
                            <p key={index} className="check-summary">
                                {text}
                            </p>
                        ))}
                        {ruleResult.summary.bullets && (
                            <ul className="check-summary-bullets">
                                {ruleResult.summary.bullets.map((bullet, i) => (
                                    <li key={i}>{bullet}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="button-container">
                        {(active || focussed) && (
                            <>
                                {/* <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            {focussed
                                                ? 'Unfocus'
                                                : 'Focus on label'}
                                        </p>
                                    }
                                    placement="top"
                                >
                                    <>
                                        {canFocus() && (
                                            <button
                                                onClick={() => handleFocus()}
                                                className={
                                                    focussed ? 'focussed' : ''
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        focussed
                                                            ? faEyeSolid
                                                            : faEye
                                                    }
                                                />
                                            </button>
                                        )}
                                    </>
                                </Tooltip> */}
                                <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            Feedback
                                        </p>
                                    }
                                    placement="top"
                                >
                                    <button
                                        onClick={() => {
                                            setModalText({
                                                title: 'User Feedback Is Coming Soon',
                                                text: 'Launching in January 2025, this feature will enable the model to learn from feedback, refine its performance, and deliver better results over time.',
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faExclamation} />
                                    </button>
                                </Tooltip>
                                {ruleResult.copy_summary !== null && (
                                    <Tooltip
                                        title={
                                            <p
                                                style={{
                                                    margin: '0px',
                                                    fontSize: '1.4em',
                                                }}
                                            >
                                                {copied
                                                    ? 'Copied'
                                                    : 'Copy summary'}
                                            </p>
                                        }
                                        placement="top"
                                    >
                                        <button
                                            onClick={() => {
                                                if (
                                                    ruleResult.copy_summary !==
                                                    null
                                                ) {
                                                    handleCopyClick(
                                                        ruleResult.copy_summary,
                                                    );
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    copied
                                                        ? faCopySolid
                                                        : faCopy
                                                }
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {/* <hr style={{ margin: '5px 0px' }} /> */}
                {/* <div className="details-container">{renderCheckDetails()}</div> */}
            </div>
        </div>
    );
};

export default RuleCard;
