import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
`;

interface SpinnerProps {
    title?: string;
}

const Spinner: React.FC<SpinnerProps> = () => {
    return (
        <ModalBackground>
            <span className="loader"></span>
        </ModalBackground>
    );
};

export default Spinner;
