import React, { FunctionComponent, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import customer_logo from './../ab-inbev-logo.svg';
import { useLocation } from 'react-router-dom';
import { OrganizationSwitcher, UserButton } from '@clerk/clerk-react';
import { clerkVariables } from '../variables/clerk';
import { useNavigateWithinOrg } from '../utils/navigation';
import useHash from '../hooks/useHash';
import { useModalContext } from '../contexts/ModalContext';

const NavBar: FunctionComponent = () => {
    const location = useLocation();
    const modalButtonRef = useRef<HTMLButtonElement>(null);

    const isActive = (path: string) => location.pathname === path;

    const navigate = useNavigateWithinOrg();
    const { setModalText } = useModalContext();

    const { setHashValue } = useHash();

    return (
        <div className="navbar-wrapper">
            <nav className="App-navbar">
                <div className="nav-title-container">
                    <img
                        src={customer_logo}
                        alt="Brand logo"
                        className="brand-logo"
                    />
                    Stickering Tool
                </div>
                <div className="settings-actions">
                    <div style={{ position: 'relative' }}>
                        <button
                            className="nav-button add"
                            onClick={() => {
                                // window.location.hash = 'upload'; // Sets the hash to #upload
                                navigate('/library');
                                setHashValue('upload');
                            }}
                            ref={modalButtonRef}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            {'Review'}
                        </button>
                    </div>
                    <button
                        onClick={() => {
                            navigate('/library');
                        }}
                        className={`nav-button ${
                            isActive('/library') ? 'selected' : ''
                        }`}
                    >
                        Library
                    </button>
                    <button
                        onClick={() => {
                            setModalText({
                                title: 'Analytics Is Coming Soon',
                                text: 'This feature will launch in January 2025. It will help you track key metrics such as approval percentage, review count, and more to provide valuable insights.',
                            });
                        }}
                        className={`nav-button ${
                            isActive('/library') ? 'selected' : ''
                        }`}
                    >
                        Analytics
                    </button>
                    {/* <button
                            onClick={() => {
                                setShowAddModal(false);
                                handleNavigation('/specifications');
                            }}
                            className={`nav-button ${
                                isActive('/specifications') ? 'selected' : ''
                            }`}
                        >
                            Specifications
                        </button> */}
                    {/* <button
                            className="nav-button"
                            onClick={handleShowUserModal}
                        >
                            <FontAwesomeIcon icon={faPhone} />
                        </button> */}
                </div>
                {/* <Protect condition={(has) => has({ role: 'org:admin' })}>
                        {organization && ( */}
                <OrganizationSwitcher
                    hidePersonal
                    afterSelectOrganizationUrl={(org) => `/orgs/${org.slug}`}
                    appearance={{
                        variables: clerkVariables,
                        elements: {
                            rootBox: {
                                marginLeft: '50px',
                            },
                            avatarBox: {
                                width: '26px',
                                height: '26px',
                            },
                        },
                    }}
                    organizationProfileProps={{
                        appearance: {
                            variables: clerkVariables,
                        },
                    }}
                />
                {/* )}
                    </Protect> */}

                {/* <OrganizationSwitcher hidePersonal>
                        <OrganizationSwitcher.OrganizationProfilePage
                            label="Custom Page"
                            url="custom"
                            labelIcon={<BuildingIcon />}
                        >
                            <div>
                                <h1>Custom Organization Profile Page</h1>
                                <p>
                                    This is the custom organization profile page
                                </p>
                            </div>
                        </OrganizationSwitcher.OrganizationProfilePage>

                        <OrganizationSwitcher.OrganizationProfilePage
                            label="Terms"
                            labelIcon={<BuildingIcon />}
                            url="terms"
                        >
                            <OrganizationList hidePersonal />
                        </OrganizationSwitcher.OrganizationProfilePage>
                    </OrganizationSwitcher> */}

                {/* <OrganizationList hidePersonal /> */}

                <UserButton
                    appearance={{
                        variables: clerkVariables,
                        elements: {
                            rootBox: {
                                marginLeft: '15px',
                            },
                            avatarBox: {
                                width: '28px',
                                height: '28px',
                            },
                        },
                    }}
                    userProfileProps={{
                        appearance: {
                            variables: clerkVariables,
                        },
                    }}
                >
                    {/* <UserButton.MenuItems>
                            <Protect
                                condition={(has) => has({ role: 'org:admin' })}
                            >
                                {organization && (
                                    <UserButton.Action
                                        label={`Manage ${organization.name}`}
                                        labelIcon={<BuildingIcon />}
                                        onClick={() => navigate('/test')}
                                    />
                                )}
                            </Protect>

                            <UserButton.Action
                                label="Change Organization"
                                labelIcon={<BuildingIcon />}
                                onClick={() => navigate('/orgs')}
                            />
                        </UserButton.MenuItems> */}
                </UserButton>
            </nav>
            {/* <OrganizationProfile /> */}
        </div>
    );
};

export default NavBar;
