import React from 'react';
import { SignUp as SignUpComponent } from '@clerk/clerk-react';
import { clerkSignInUpElements, clerkVariables } from '../variables/clerk';

const SignUp = () => {
    return (
        <div className="auth-container">
            <SignUpComponent
                path="/sign-up"
                routing="path"
                signInUrl="/sign-in"
                appearance={{
                    elements: clerkSignInUpElements,
                    variables: clerkVariables,
                }}
            />
        </div>
    );
};

export default SignUp;
