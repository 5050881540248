export function formatDate(
    timestamp: number,
    includeTime = false,
    useRelativeLabels = false,
): string {
    const date = new Date(timestamp * 1000);
    const now = new Date();

    // Helper function to check if two dates are the same calendar day
    const isSameDay = (d1: Date, d2: Date): boolean => {
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    };

    // Helper function to check if a date is within the last n calendar days
    const isWithinLastDays = (d: Date, days: number): boolean => {
        const compareDate = new Date(now);
        compareDate.setHours(0, 0, 0, 0);
        const targetDate = new Date(d);
        targetDate.setHours(0, 0, 0, 0);

        // Go back 'days' number of days
        compareDate.setDate(compareDate.getDate() - days);

        return targetDate >= compareDate;
    };

    // Helper function to check if a date is yesterday
    const isYesterday = (d: Date): boolean => {
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        return isSameDay(d, yesterday);
    };

    const weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    // Prepare the time format
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    if (useRelativeLabels) {
        let label = '';
        if (isSameDay(date, now)) {
            label = 'Today';
        } else if (isYesterday(date)) {
            label = 'Yesterday';
        } else if (isWithinLastDays(date, 6)) {
            label = weekdays[date.getDay()];
        } else {
            // Format the date as DD/MM/YYYY for dates more than 6 days ago
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return includeTime
                ? `${day}/${month}/${year} at ${formattedTime}`
                : `${day}/${month}/${year}`;
        }
        return includeTime ? `${label} at ${formattedTime}` : label;
    }

    // Format the date as DD/MM/YYYY for non-relative dates
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return includeTime
        ? `${day}/${month}/${year} at ${formattedTime}`
        : `${day}/${month}/${year}`;
}
