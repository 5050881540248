import { createContext, useContext } from 'react';

export interface FetchedResult {
    outcome: 'Accept' | 'Warning' | 'Reject';
    id: string;
}

export interface UploadProgress {
    active: boolean;
    imageUrl: string;
    imageId: string;
    title: string;
    timeStamp: Date;
    status:
        | 'queued'
        | 'upload'
        | 'checking'
        | 'fetchingResult'
        | 'successful'
        | 'error'
        | 'waiting';
    progress: number;
    fetchedResult: null | FetchedResult;
}

export interface UploadProgressContextType {
    uploadProgressArray: UploadProgress[];
    setUploadProgressArray: React.Dispatch<
        React.SetStateAction<UploadProgress[]>
    >;
    uploadProgressArrayRef: React.MutableRefObject<UploadProgress[]>;
}

export const UploadProgressContext = createContext<
    UploadProgressContextType | undefined
>(undefined);

export function useUploadProgressContext() {
    const uploadProgressContext = useContext(UploadProgressContext);

    if (uploadProgressContext === undefined) {
        throw new Error(
            'useUploadProgressContext must be used with a UploadProgressContext',
        );
    }

    return uploadProgressContext;
}
