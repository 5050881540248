import React, { FunctionComponent } from 'react';
import { RuleResult } from '../types';
import AssetDetailsInformation from './AssetDetailsInformation';
import AssetDetailsTitleBar from './AssetDetailsTitleBar';
import ImageCanvas from './ImageCanvas';

interface AssetDetailsBrowserProps {
    handleDeleteButton: () => void;
    handleRuleSelected: (rule: RuleResult) => void;
}

const AssetDetailsBrowser: FunctionComponent<AssetDetailsBrowserProps> = ({
    handleDeleteButton,
    handleRuleSelected,
}) => {
    return (
        <div className="asset-details-container">
            <ImageCanvas />
            <div className="rule-results-detail-container">
                <AssetDetailsTitleBar />
                <AssetDetailsInformation
                    handleRuleSelected={handleRuleSelected}
                />
            </div>
        </div>
    );
};

export default AssetDetailsBrowser;
