import React, { FunctionComponent } from 'react';
import Filters from './Filters';
import { useLibraryContext } from '../contexts/LibraryContext';
import SearchContainer from './SearchContainer';
import { isMobile } from 'react-device-detect';
import { FilterType } from '../types';

const LibraryControlContainer: FunctionComponent = () => {
    const libraryContext = useLibraryContext();

    const filterProps = {
        filters: [
            {
                name: FilterType.DATE,
                display_name: 'Date',
            },
            {
                name: FilterType.USER,
                display_name: 'User',
            },
            {
                name: FilterType.APPROVAL,
                display_name: 'Approval',
            },
            {
                name: FilterType.BRAND,
                display_name: 'Brand',
            },
        ],
        appliedFilters: libraryContext.state.appliedFilters,
        setAppliedFilters: libraryContext.setters.setAppliedFilters,
        filterCounts: libraryContext.state.filterCounts,
        sortProps: {
            appliedSortOption: libraryContext.state.appliedSortOption,
            setAppliedSortOption: libraryContext.setters.setAppliedSortOption,
        },
    };

    return (
        <>
            <div className="filter-controls">
                <h2>Library</h2>
                {!isMobile && <Filters {...filterProps} />}
                <SearchContainer
                    appliedSearchString={libraryContext.state.searchString}
                    setAppliedSearchString={
                        libraryContext.setters.setSearchString
                    }
                />
            </div>
            {isMobile && (
                <div className="filter-controls mobile">
                    <Filters {...filterProps} />
                </div>
            )}
        </>
    );
};

export default LibraryControlContainer;
