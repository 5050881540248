import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useState,
} from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchContainerProps {
    appliedSearchString: string;
    setAppliedSearchString: Dispatch<SetStateAction<string>>;
}

const SearchContainer: FunctionComponent<SearchContainerProps> = ({
    appliedSearchString,
    setAppliedSearchString,
}) => {
    const [searchValue, setSearchValue] = useState<string>(appliedSearchString);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        if (event.target.value === '') {
            setAppliedSearchString(event.target.value);
        }
    };

    const handleSearchKeyPress = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (event.key === 'Enter') {
            setAppliedSearchString(event.currentTarget.value);
        }
    };

    return (
        <div className="search-container">
            <span className="search-icon">
                <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
                value={searchValue}
                onChange={handleSearchChange}
                type="text"
                className="search-input"
                onKeyDown={handleSearchKeyPress}
                placeholder="Search..."
            />
        </div>
    );
};

export default SearchContainer;
