import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface ModalText {
    title: string;
    text: string;
}

interface ModalContextType {
    modalText: ModalText | null;
    setModalText: React.Dispatch<React.SetStateAction<ModalText | null>>;
}

export const ModalContext = createContext<ModalContextType | undefined>(
    undefined,
);

export function useModalContext() {
    const modalContext = useContext(ModalContext);

    if (modalContext === undefined) {
        throw new Error('useModalContext must be used with a ModalContext');
    }

    return modalContext;
}

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modalText, setModalText] = useState<ModalText | null>(null);

    return (
        <ModalContext.Provider value={{ modalText, setModalText }}>
            {children}
        </ModalContext.Provider>
    );
};
