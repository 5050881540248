import { useAuth, useOrganizationList } from '@clerk/clerk-react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Provider that syncs the organization from URL to application state
 * Expects URLs in the format: /org/{orgSlug}/*
 */
const OrganizationSyncProvider: React.FC = () => {
    const navigate = useNavigate();
    const { setActive, isLoaded } = useOrganizationList();
    // Get the organization slug from the session
    const { orgSlug } = useAuth();
    // Get the organization slug from the URL
    // e.g. https://example.com/orgSlug/<your-org-slug>
    const { orgSlug: urlOrgSlug } = useParams<{ orgSlug: string }>();

    useEffect(() => {
        if (!isLoaded) return;
        // If the org slug in the URL is not the same as the org slug in the session (the active organization),
        // set the active organization to be the org from the URL.
        if (orgSlug === null) {
            navigate('/orgs');
        }
        if (urlOrgSlug !== orgSlug) {
            void setActive({ organization: urlOrgSlug });
        }
    }, [orgSlug, isLoaded, setActive, urlOrgSlug]);

    return null;
};

export default OrganizationSyncProvider;

// src/providers/organization/index.ts
export { default as OrganizationSyncProvider } from './OrganizationSyncProvider';
