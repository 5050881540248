import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from '../components/NavBar';
import { handleLibraryRequest } from '../utils/backendServices';
import UploadStateModal from '../components/UploadStateModal';
import { useLibraryContext } from '../contexts/LibraryContext';
import Spinner from '../components/Spinner';
import {
    UploadProgress,
    UploadProgressContext,
} from '../contexts/UploadProgressContext';
import { useInterval } from '../hooks/useInterval';
import { useUploadProgress } from '../hooks/useUploadProgress';
import Library from './Library';
import AssetDetails from '../components/AssetDetails';
import { freeAccess } from '../providers/AuthProvider';
import { useAuth } from '@clerk/clerk-react';
import OrganizationSyncProvider from '../providers/OrganizationSyncProvider';
import NavigateWithinOrg from '../utils/navigation';
import FeatureNotAvailableModal from '../components/FeatureNotAvailableModal';
import { useModalContext } from '../contexts/ModalContext';
import { useLatestSuccessfulUpload } from '../contexts/UploadContext';

const MainApp = () => {
    const { orgId } = useAuth();

    const [userModalVisible, setUserModalVisible] = useState<boolean>(false);
    const [firstRequestSuccessful, setFirstRequestSuccessful] =
        useState<boolean>(false);

    const handleUserModalClose = () => {
        setUserModalVisible(false);
    };
    const handleUserModalOpen = () => {
        setUserModalVisible(true);
    };

    const libraryContext = useLibraryContext();
    const { modalText, setModalText } = useModalContext();

    const latestSuccessfulUpload = useLatestSuccessfulUpload();
    // todo: should be moved into Library Component

    useEffect(() => {
        // if (organization) {
        handleLibraryRequest(libraryContext)
            .then(() => {
                setFirstRequestSuccessful(true);
            })
            .catch((error) => {
                console.log(error);
            });
        //
    }, [
        libraryContext.state.appliedFilters,
        libraryContext.state.searchString,
        libraryContext.state.appliedSortOption,
        orgId,
        latestSuccessfulUpload,
    ]);

    useInterval(() => {
        handleLibraryRequest(
            libraryContext,
            libraryContext.state.currentPage,
            false,
        ).catch((error) => {
            console.log(error);
        });
    }, 60000);

    const [uploadProgressArray, setUploadProgressArray] = useState<
        UploadProgress[]
    >([]);
    const uploadProgressArrayRef = useRef<UploadProgress[]>([]);
    uploadProgressArrayRef.current = uploadProgressArray;

    if (!freeAccess) {
        useUploadProgress(uploadProgressArrayRef, setUploadProgressArray);
    }

    const routes = [
        { path: '/library', element: <Library /> },
        {
            path: '/library/:id',
            element: <AssetDetails />,
        },
    ];

    return (
        <>
            <OrganizationSyncProvider />
            {!firstRequestSuccessful ? (
                <Spinner />
            ) : (
                <>
                    <FeatureNotAvailableModal
                        modalText={modalText}
                        onClose={() => setModalText(null)}
                    />
                    <NavBar />
                    <div className="App">
                        <UploadProgressContext.Provider
                            value={{
                                uploadProgressArray,
                                setUploadProgressArray,
                                uploadProgressArrayRef,
                            }}
                        >
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <NavigateWithinOrg to={'/library'} />
                                    }
                                />
                                {routes.map((route) => {
                                    return (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            element={route.element}
                                        />
                                    );
                                })}
                            </Routes>
                            <UploadStateModal />
                        </UploadProgressContext.Provider>
                    </div>
                </>
            )}
        </>
    );
};
export default MainApp;
