import React, { FunctionComponent } from 'react';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { useLibraryContext } from '../contexts/LibraryContext';
import LibraryControlContainer from '../components/LibraryControlContainer';
import AssetGallery from '../components/AssetGallery';
import { Route, Routes, useLocation } from 'react-router-dom';
import UploadAsset from './UploadAsset';
import useMarket from '../hooks/useMarket';
import useHash from '../hooks/useHash';

const Library: FunctionComponent = () => {
    const {
        state: {
            libraryIsLoading,
            currentPage,
            totalPages,
            imageUrls,
            libraryAssets,
        },
    } = useLibraryContext();

    const isPrevPageAvailable = currentPage > 1;
    const isNextPageAvailable = currentPage < totalPages;
    const location = useLocation();
    const market = useMarket();
    const { hash } = useHash();
    console.log('hash', hash, window.location.hash, market);

    return (
        <>
            {hash === '#upload' && market && <UploadAsset market={market} />}

            <div className="wrapper-container">
                <LibraryControlContainer />
                {libraryIsLoading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <Spinner title="Loading..." />
                    </div>
                )}
                {!libraryIsLoading && (
                    <AssetGallery
                        imageUrls={imageUrls}
                        libraryAssets={libraryAssets}
                    />
                )}
                {libraryAssets.length > 0 && (
                    <Pagination
                        totalPages={totalPages}
                        isPrevPageAvailable={isPrevPageAvailable}
                        isNextPageAvailable={isNextPageAvailable}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
};

export default Library;
