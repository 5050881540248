import React from 'react';
import {
    Activity,
    ActivityType,
    CommentActivityContent,
    StatusChangeActivityContent,
    UploadActivityContent,
} from '../types';
import StatusIndicator from './StatusIndicator';
import { formatDate } from '../utils/timeUtils';

interface ActivityItemProps {
    activity: Activity;
    firstActivity: boolean;
}

export const ActivityItem: React.FC<ActivityItemProps> = ({
    activity,
    firstActivity,
}) => {
    const renderActivityDetails = () => {
        switch (activity.activity_type) {
            case ActivityType.COMMENT:
                return (
                    <>
                        <span className="content">
                            <span className="highlighted">
                                {activity.user_details.first_name}{' '}
                                {activity.user_details.last_name}
                            </span>{' '}
                            posted a comment.
                        </span>
                        <div className="text-field">
                            <p>
                                {
                                    (activity.content as CommentActivityContent)
                                        .content
                                }
                            </p>
                        </div>
                    </>
                );

            case ActivityType.STATUS_CHANGE:
                return (
                    <>
                        <span className="content">
                            <span className="highlighted">
                                {activity.user_details.first_name}{' '}
                                {activity.user_details.last_name}
                            </span>{' '}
                            changed approval status from{' '}
                            <span>
                                <StatusIndicator
                                    decision={
                                        (
                                            activity.content as StatusChangeActivityContent
                                        ).old_status
                                    }
                                    isBig={false}
                                />
                            </span>{' '}
                            to{' '}
                            <span>
                                <StatusIndicator
                                    decision={
                                        (
                                            activity.content as StatusChangeActivityContent
                                        ).new_status
                                    }
                                    isBig={false}
                                />
                            </span>
                            .
                        </span>
                        {(activity.content as StatusChangeActivityContent)
                            .reason && (
                            <div className="text-field">
                                <p>
                                    {
                                        (
                                            activity.content as StatusChangeActivityContent
                                        ).reason
                                    }
                                </p>
                            </div>
                        )}
                    </>
                );

            case ActivityType.UPLOAD:
                return (
                    <span className="content">
                        <span className="highlighted">
                            {activity.user_details.first_name}{' '}
                            {activity.user_details.last_name}
                        </span>{' '}
                        uploaded the asset{' '}
                        <span className="highlighted">
                            {
                                (activity.content as UploadActivityContent)
                                    .asset_name
                            }
                        </span>
                        , which was{' '}
                        <StatusIndicator
                            decision={
                                (activity.content as UploadActivityContent)
                                    .approval_status
                            }
                            isBig={false}
                        />{' '}
                        by the Stickering Tool.
                    </span>
                );

            default:
                return null;
        }
    };

    return (
        <div className="activity-item">
            <div className="left-side">
                {!firstActivity && <div className="line"></div>}
                <img
                    className="activity-avatar"
                    src={activity.user_details.avatar_url}
                />
            </div>
            <div className="right-side">
                {renderActivityDetails()}
                <div className="time">
                    {formatDate(activity.timestamp, true, true)}
                </div>
            </div>
        </div>
    );
};
