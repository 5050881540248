import React from 'react';

export interface InputFieldTitleProps {
    title: string;
    required: boolean;
}

const InputFieldTitle: React.FC<InputFieldTitleProps> = ({
    title,
    required,
}) => {
    return (
        <p className="input-field-title">
            {title}
            {required && (
                <span className="required-indicator" aria-hidden="true">
                    *
                </span>
            )}
        </p>
    );
};

export default InputFieldTitle;
