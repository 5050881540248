import React, { useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import SearchBar from './SearchBar';
import RuleCard from './RuleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssetContext } from '../contexts/AssetContext';
import { RuleOutcome, RuleResult } from '../types';

interface RuleChecksTableProps {
    ruleResults: RuleResult[];
    onRuleSelected: (selectedRule: RuleResult) => void;
}

const RuleChecksTable: React.FC<RuleChecksTableProps> = ({
    ruleResults,
    onRuleSelected,
}) => {
    const [viewAll, setViewAll] = useState<boolean>(false);
    const {
        state: { focussedRule },
        setters: { setFocussedRule },
    } = useAssetContext();
    const [filteredRules, setFilteredRules] =
        useState<RuleResult[]>(ruleResults);

    const handleSearch = (searchTerm: string) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        const filtered = ruleResults.filter((checkResult) =>
            Object.values(checkResult).some(
                (value) =>
                    value !== null &&
                    value !== undefined &&
                    value.toString().toLowerCase().includes(lowercasedTerm),
            ),
        );
        setFilteredRules(filtered);
    };
    const failedRules = ruleResults.filter(
        (rule) =>
            rule.outcome === RuleOutcome.WARNING ||
            rule.outcome === RuleOutcome.FAIL,
    ).length;

    // const numFailWarning = filteredRules.filter(
    //     (rule) => rule.outcome === 'Warning' || rule.outcome === 'Fail',
    // ).length;
    const numRuleDisplayed = Math.max(3, failedRules);
    const numFailWarning = 1 * 0;
    const displayedRules = viewAll
        ? filteredRules
        : filteredRules.slice(0, Math.max(numFailWarning, numRuleDisplayed));

    return (
        <div style={{ paddingRight: '10px' }}>
            <div className="rules-check-controls">
                <h3>Rule Checks</h3>
                <SearchBar onSearch={handleSearch} searchOnEnter={false} />
            </div>
            <div className="rule-checks">
                {displayedRules.map((rule, index) => (
                    <RuleCard
                        key={index}
                        ruleResult={rule}
                        handleFeedbackClick={() => onRuleSelected(rule)}
                        focussed={rule.name == focussedRule}
                        handleFocus={() => {
                            setFocussedRule(
                                rule.name == focussedRule ? null : rule.name,
                            );
                        }}
                    />
                ))}
            </div>
            {filteredRules.length > numRuleDisplayed && (
                <button
                    onClick={() => {
                        setViewAll(!viewAll);
                    }}
                    className="view-more-less-button"
                >
                    {`View ${viewAll ? 'less ' : 'all '}`}
                    <FontAwesomeIcon icon={viewAll ? faAngleUp : faAngleDown} />
                </button>
            )}
        </div>
    );
};

export default RuleChecksTable;
