import { FunctionComponent, useState } from 'react';
import FilterButton from './FilterButton';
import React from 'react';
import SortButton from './SortButton';
import { FilterConfig, AppliedFilter, FilterType } from '../types';
import { sortOptions } from '../hooks/useDefaultLibraryContext';
import { useLibraryContext } from '../contexts/LibraryContext';

interface FiltersProps {
    filters: FilterConfig[];
}

const Filters: FunctionComponent<FiltersProps> = ({ filters }) => {
    const {
        setters: { setAppliedFilters, setAppliedSortOption },
        state: { appliedFilters, appliedSortOption, filterCounts },
    } = useLibraryContext();

    const [activeDropdown, setActiveDropdown] = useState<
        'sort' | FilterType | null
    >(null);

    const onFilterApplication = (newFilter: AppliedFilter) => {
        setAppliedFilters((prevFilters) => {
            const existingFilterIndex = prevFilters.findIndex(
                (filter) => filter.type === newFilter.type,
            );

            if (existingFilterIndex > -1) {
                const updatedFilters = [...prevFilters];
                updatedFilters[existingFilterIndex] = newFilter;
                return updatedFilters;
            } else {
                return [...prevFilters, newFilter];
            }
        });
    };

    const inactivateFilter = (filterName: FilterType) => {
        setAppliedFilters((prevFilters) =>
            prevFilters.filter((filter) => filter.type !== filterName),
        );
    };

    const handleToggleActive = (toggleName: 'sort' | FilterType | null) => {
        setActiveDropdown(activeDropdown === toggleName ? null : toggleName);
    };
    return (
        <div className="filter-buttons">
            <SortButton
                showDropdown={activeDropdown == 'sort'}
                appliedSortOption={appliedSortOption}
                setAppliedSortOption={setAppliedSortOption}
                sortOptions={sortOptions}
                onToggleActive={handleToggleActive}
            />
            {filters?.map((filter) => {
                const filterOptions = filterCounts.find(
                    (filterCount) => filterCount.type === filter.name,
                )?.options;
                return (
                    <FilterButton
                        key={filter.name}
                        filterDisplayName={filter.display_name}
                        filterName={filter.name}
                        isActive={activeDropdown === filter.name}
                        onToggleActive={handleToggleActive}
                        handleFilterApplication={onFilterApplication}
                        optionsWithCount={filterOptions ? filterOptions : []}
                        appliedFilter={appliedFilters.find(
                            (appliedFilter) =>
                                appliedFilter.type == filter.name,
                        )}
                        inactivateFilter={inactivateFilter}
                    />
                );
            })}
        </div>
    );
};

export default Filters;
