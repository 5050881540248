// src/components/organization/OrganizationList.tsx
import React from 'react';
import {
    OrganizationList as ClerkOrganizationList,
    SignOutButton,
    useClerk,
    useOrganization,
    useOrganizationList,
} from '@clerk/clerk-react';
import { clerkSignInUpElements, clerkVariables } from '../variables/clerk';

const OrganizationList = () => {
    const { userMemberships, userInvitations } = useOrganizationList({
        userMemberships: { infinite: true },
        userInvitations: { infinite: true },
    });

    return (
        <div className="auth-container">
            {userMemberships.isLoading == false &&
                userInvitations.isLoading == false &&
                userMemberships.data &&
                userInvitations.data && (
                    <>
                        {userMemberships.data.length > 0 ||
                        userInvitations.data.length > 0 ? (
                            <ClerkOrganizationList
                                hidePersonal={true}
                                afterSelectOrganizationUrl={(org) =>
                                    `/orgs/${org.slug}`
                                }
                                appearance={{
                                    elements: clerkSignInUpElements,
                                    variables: clerkVariables,
                                }}
                            />
                        ) : (
                            <>
                                <p>
                                    <b>Error occured: </b> Please reach out to
                                    your organisation admin to send you another
                                    invite or email{' '}
                                    <a href="mailto:info@algospark.com">
                                        info@algospark.com
                                    </a>
                                    . You can logout{' '}
                                    <SignOutButton>here</SignOutButton>.
                                </p>
                            </>
                        )}
                    </>
                )}
        </div>
    );
};

export default OrganizationList;
