import React, { FunctionComponent } from 'react';
import { useUploadContext } from '../contexts/UploadContext';
import AssetUploadState from './AssetUploadState';

const UploadStateModal: FunctionComponent = () => {
    const { uploadStateArray, hideUploadState } = useUploadContext();

    return uploadStateArray.length > 0 ? (
        <div className="upload-progress-container">
            {uploadStateArray.map(
                (uploadState) =>
                    uploadState.display && (
                        <AssetUploadState
                            key={uploadState.id}
                            uploadState={uploadState}
                            handleClose={hideUploadState}
                        />
                    ),
            )}
        </div>
    ) : null;
};

export default UploadStateModal;
