import React, { FunctionComponent, useEffect, useState } from 'react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'; // Import the arrow icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { State, UploadType, UploadState } from '../types';
import { useNavigateWithinOrg } from '../utils/navigation';

interface AssetUploadStateProps {
    uploadState: UploadState;
    handleClose: (assetId: string) => void;
}

const statetoText = (state: State, uploadType: UploadType): string => {
    const displayUploadType =
        uploadType === 'spec' ? 'specifications' : 'label';
    switch (state) {
        case 'pending':
            return `Queuing ${displayUploadType}...`;
        case 'uploading':
            return `Uploading ${displayUploadType}...`;
        case 'processing':
            return `Processing ${displayUploadType}...`;
        case 'successful':
            return uploadType === 'spec'
                ? 'Specifications ingest successful.'
                : 'Label check successful.';
        case 'failed':
            return 'Error: Please try reuploading.';
        default:
            return '';
    }
};

const getEstimatedTotalTime = (uploadState: UploadState): number => {
    const { upload_type } = uploadState;
    switch (upload_type) {
        case 'spec':
            return 30;
        case 'label':
            return 30;
        default:
            return 30;
    }
};

const AssetUploadState: FunctionComponent<AssetUploadStateProps> = ({
    uploadState,
    handleClose,
}) => {
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const navigate = useNavigateWithinOrg();

    const handleView = () => {
        navigate(`/library/${uploadState.id}`);
        handleClose(uploadState.id);
    };

    useEffect(() => {
        const startTime = uploadState.upload_time * 1000; // Convert to milliseconds

        const updateElapsedTime = () => {
            const currentTime = Date.now();
            const newElapsedTime = currentTime - startTime;
            setElapsedTime(newElapsedTime);
        };

        // Update every 10 milliseconds
        const interval = setInterval(updateElapsedTime, 10);

        // Also update immediately
        updateElapsedTime();

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, [uploadState.upload_time]);

    // Calculate progress and etaText based on elapsedTime
    const estimatedTotalTime = getEstimatedTotalTime(uploadState) * 1000; // in milliseconds

    let progress = (elapsedTime / estimatedTotalTime) * 90;
    if (progress > 90) progress = 90;

    // If upload is successful or failed, set progress to 100%
    if (uploadState.state === 'successful' || uploadState.state === 'failed') {
        progress = 100;
    }

    // Calculate ETA only if not in the final state (successful/failed)
    let etaText = '';
    if (uploadState.state !== 'successful' && uploadState.state !== 'failed') {
        let eta = (estimatedTotalTime - elapsedTime) / 1000; // in seconds
        if (eta < 0) eta = 0;

        // Round ETA up to the next 10s step
        const etaStep = Math.ceil(eta / 10) * 10;
        etaText = etaStep > 0 ? `<${etaStep}s` : '<10s';
    }

    // Determine the color of the progress bar based on the upload state
    let progressBarColor = '#e5b611'; // default in-progress color
    if (uploadState.state === 'successful') {
        progressBarColor = '#389E0D'; // green when successful
    } else if (uploadState.state === 'failed') {
        progressBarColor = 'red'; // red when failed
    }

    return (
        <div className="upload-progress-box">
            <div
                className="close-button"
                onClick={() => handleClose(uploadState.id)}
            />
            <div className="right-container">
                <div className="info-container">
                    <span>
                        <p className="asset-title">{uploadState.asset_name}</p>
                        {uploadState.state === 'successful' && (
                            <button
                                className="view-button"
                                onClick={handleView}
                            >
                                View <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        )}
                    </span>
                    <div className="progress-box">
                        <div className="progress-info">
                            <p className="status-text">
                                {statetoText(
                                    uploadState.state,
                                    uploadState.upload_type,
                                )}
                            </p>
                            {uploadState.state !== 'successful' &&
                                uploadState.state !== 'failed' && (
                                    <div className="eta-info">
                                        <FontAwesomeIcon icon={faClock} />
                                        <p className="eta-text">{etaText}</p>
                                    </div>
                                )}
                        </div>
                        <div className="progress-bar">
                            <div
                                className="progress-color"
                                style={{
                                    width: `${progress}%`,
                                    backgroundColor: progressBarColor,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetUploadState;
